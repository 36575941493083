var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('PageTitle',{staticClass:"font font-weight-bold",attrs:{"text":"Pumps"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 mr-3",attrs:{"small":"","fab":"","icon":""},on:{"click":function($event){return _vm.$store.dispatch('pumps/list')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Refresh page")])])],1),_c('div',[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text font",staticStyle:{"font-size":"12px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.open('add')}}},[_vm._v(" New Pump "),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('h4',{staticClass:"blue-grey--text font",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List of Pumps ")])],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light font font-weight-medium",attrs:{"headers":_vm.headers,"items":_vm.pumps,"item-key":"product","loading-text":"Loading... Please wait","loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"item.pumpProductId",fn:function(ref){
var item = ref.item;
return _vm._l((item.pumpProductId),function(product){return _c('v-chip',{key:product.id,staticClass:"ma-1",attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:"font font-weight-bold"},[_vm._v(_vm._s(product.productId.name))])])})}},{key:"item.numberOfNozzles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nozzles.length)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tag":"a","fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.open('edit', 'pumps/details', item.id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("edit")])])]}}],null,true)},[_c('span',[_vm._v("Edit Pump")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","small":"","disabled":item.nozzles.length === 0,"color":"primary"},on:{"click":function($event){return _vm.open('details', 'pumps/details', item.id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md"},[_vm._v("room_preferences")])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Add Opening Meter Readings")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","small":"","color":item.isEnabled ? 'error' : 'success'},on:{"click":function($event){return _vm.open('alert', 'pumps/details', item.id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-weight-bold font-size-md"},[_vm._v(_vm._s(item.isEnabled ? "unpublished" : "done_all"))])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.isEnabled ? "Disable" : "Enable")+" Pump")])])]}}])})],1)]],2)],1),_c('AddNewPump',{attrs:{"state":_vm.dialog('add'),"loading":_vm.loading,"products":_vm.searchResults},on:{"close":_vm.close}}),_c('EditPump',{attrs:{"state":_vm.dialog('edit'),"loading":_vm.loading,"progress":_vm.isListLoading,"products":_vm.searchResults,"details":_vm.pumpDetails},on:{"close":_vm.close}}),_c('OpeningMeterReadingDialog',{attrs:{"state":_vm.dialog('details'),"loading":_vm.loading,"progress":_vm.subLoading,"details":_vm.pumpDetails},on:{"addOpeningMeterReading":_vm.addOpeningMeterReading,"close":_vm.close}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}}),_c('ConfirmationDialog',{attrs:{"title":"Acivate Or Deactivate Pump","state":_vm.dialog('alert'),"loading":_vm.loading,"body":"If this pump is active, this action will deactivate it and no activities can be recorded for this pump"},on:{"save":_vm.enablePump,"close":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }