<template>
  <v-container>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle text="Pumps" class="font font-weight-bold" />
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            small
            fab
            icon
            @click="$store.dispatch('pumps/list')"
            class="mt-2 mr-3"
          >
            <i class="material-icons-outlined">refresh</i>
          </v-btn>
        </template>
        <span class="font font-weight-medium font-size-sm">Refresh page</span>
      </v-tooltip>
    </div>
    <div>
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text font"
        style="font-size: 12px"
        @click="open('add')"
      >
        New Pump <v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="blue-grey--text font"
      >
        List of Pumps
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="pumps"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="isListLoading"
              class="box-shadow-light font font-weight-medium"
            >
              <template #item.pumpProductId="{item}">
                <v-chip
                  label
                  small
                  color="primary"
                  class="ma-1"
                  v-for="product in item.pumpProductId"
                  :key="product.id"
                >
                  <span class="font font-weight-bold">{{
                    product.productId.name
                  }}</span>
                </v-chip>
              </template>
              <template #item.numberOfNozzles="{item}">
                {{ item.nozzles.length }}
              </template>
              <template #item.createdAt="{item}">
                {{ item.createdAt | format }}
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      tag="a"
                      fab
                      small
                      @click="open('edit', 'pumps/details', item.id)"
                      color="primary"
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-sm">edit</i>
                    </v-btn>
                  </template>
                  <span>Edit Pump</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="open('details', 'pumps/details', item.id)"
                      icon
                      fab
                      small
                      :disabled="item.nozzles.length === 0"
                      color="primary"
                    >
                      <i class="material-icons-outlined font-size-md"
                        >room_preferences</i
                      >
                    </v-btn>
                  </template>
                  <span class="font font-weight-medium font-size-sm"
                    >Add Opening Meter Readings</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="open('alert', 'pumps/details', item.id)"
                      icon
                      fab
                      small
                      :color="item.isEnabled ? 'error' : 'success'"
                    >
                      <i
                        class="material-icons-outlined font-weight-bold font-size-md"
                        >{{ item.isEnabled ? "unpublished" : "done_all" }}</i
                      >
                    </v-btn>
                  </template>
                  <span class="font font-weight-medium font-size-sm"
                    >{{ item.isEnabled ? "Disable" : "Enable" }} Pump</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <AddNewPump
      :state="dialog('add')"
      @close="close"
      :loading="loading"
      :products="searchResults"
    />
    <EditPump
      :state="dialog('edit')"
      @close="close"
      :loading="loading"
      :progress="isListLoading"
      :products="searchResults"
      :details="pumpDetails"
    />
    <OpeningMeterReadingDialog
      :state="dialog('details')"
      :loading="loading"
      :progress="subLoading"
      :details="pumpDetails"
      @addOpeningMeterReading="addOpeningMeterReading"
      @close="close"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
    <ConfirmationDialog
      title="Acivate Or Deactivate Pump"
      :state="dialog('alert')"
      @save="enablePump"
      :loading="loading"
      @close="close"
      body="If this pump is active, this action will deactivate it and no activities can be recorded for this pump"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import DialogMixins from "../../mixins/DialogMixins";
import { mapGetters } from "vuex";
export default {
  name: "Pumps",
  components: {
    PageTitle,
    SnackBar: () => import(`../../components/SnackBar`),
    AddNewPump: () => import(`./dialogs/AddNewPump`),
    EditPump: () => import(`./dialogs/EditPumps`),
    OpeningMeterReadingDialog: () => import(`./dialogs/AddOpeningMeterReading`),
    ConfirmationDialog: () => import(`../sales/components/ConfirmationDialog`)
  },
  data() {
    return {
      progress: false,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      products: []
    };
  },
  mixins: [SnackBarMixins, DialogMixins],
  computed: {
    ...mapGetters({
      subLoading: "getIsSubLoading",
      pumps: "pumps/getPumpList",
      isListLoading: "getIsListLoading",
      loading: "getIsLoading",
      searchResults: "products/getProductList",
      pumpDetails: "pumps/getPumpDetails"
    }),
    headers() {
      return [
        {
          text: "",
          value: "avatar",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          value: "name"
        },
        {
          text: "Products",
          align: "start",
          value: "pumpProductId"
        },
        {
          text: "No. Of Nozzles",
          align: "start",
          value: "numberOfNozzles"
        },
        { text: "Date Added", value: "createdAt" },
        { text: "Actions", value: "actions", align: "center" }
      ];
    }
  },
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    },
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    }
  },

  watch: {
    options: {
      handler(value) {
        console.log(value);
      },
      deep: true
    }
  },
  methods: {
    addOpeningMeterReading(payload) {
      this.$store.dispatch("pumps/addOpeningMeterReading", payload);
    },
    enablePump() {
      const details = this.$store.getters["pumps/getPumpDetails"];
      this.$store.dispatch("pumps/activatePump", details);
    }
  },
  created() {
    this.$store.dispatch("pumps/list");
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
